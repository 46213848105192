import {mapActions, mapGetters} from "vuex";

import Avatar from "@/components/avatar";
import Badge from "@/components/badge";
import Button from "@/components/button";
import ChartDonut from "@/containers/chart-donut";
import Dropdown from "@/components/dropdown";
import Input from "@/components/input";
import {range} from "lodash";
import {jwtBlackboxApi} from "../../main.js";
import Loader from "@/components/loader";
import axios from "@/axios";

export default {
    name: "neo-cases",
    components: {
        "neo-avatar": Avatar,
        "neo-button": Button,
        "neo-dropdown": Dropdown,
        "neo-input": Input,
        "neo-chart-donut": ChartDonut,
        "neo-badge": Badge,
        "neo-loader": Loader,
    },
    props: [],
    data() {
        return {
            stats: {
                pending: {
                    bg: "#fff9e6",
                    color: "#f7be07",
                },
            },
            cases: [],
            caseKeys: ["", "Case Type", "Subject", "Phone", "Email", "Case Id"],
            fetchingCases: true,
            range: range(0, 20),
            tHeads: [
                "subject",
                // "jobId",
                "email",
                "phone",
                // "status",
                // "riskLevel",
                // "more",
                "view case",
            ],
            options: {
                dateRange: ["option1", "option2", "option3"],
                status: ["option4", "option2", "option3"],
                sortBy: ["option1", "option7", "option3"],
                analyst: ["option1", "option7", "option3"],
            },
            filterControls: {
                dateRange: "option1",
                status: "option1",
                sortBy: "option1",
                analyst: "option1",
                search: "",
            },
            sampleCase: {
                subject: "Thomas Blake",
                jobId: "2342-34234",
                email: "tommblakc@gmail.com",
                phone: "+1 234 234 5443",
                status: "pending",
                riskLevel: 45,
            },
        };
    },
    computed: {
        ...mapGetters(["getCasesData"]),
        filteredCases() {
            if (this.filterControls.search) {
                return this.cases.filter((item) => {
                    return [item._id, item.case_type, item.data.full_name, item.data.mobile[0], item.data.emails[0]].map((e) => e.toLowerCase()).some((el) => el.startsWith(this.filterControls.search.toLowerCase()));
                });
            } else {
                return this.cases;
            }
        },
    },
    created() {
        this.fetchCasesData();
    },
    mounted() {
        this.fetchCases();
    },
    methods: {
        ...mapActions(["fetchCasesData"]),
        onViewCase(item) {
            this.$router.push({path: `/research/${item}`, params: {id: item}}).catch(() => null);
        },
        async fetchCases() {
            let response = await jwtBlackboxApi.get("/cases/org-cases");
            this.fetchingCases = false;
            this.cases = response.data;
        },
    },
};
